<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Group List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="table-responsive">
      <table id="mytable"
             class="table table-columned"
             data-search="false"
             data-show-refresh="false"
             data-show-columns="false"
             data-pagination="false"
             data-page-list="[10, 25, 50, 100,ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-formatter="runningFormatter" data-width="75" >S.No</th>
<!--          <th data-field="id" data-sortable="true" data-width="75">Code</th>-->
          <th data-field="name" data-sortable="true">Name</th>
          <th data-field="type" data-sortable="true">Type</th>
          <th data-field="hsn" data-sortable="true">HSN</th>
          <th data-field="gstrate" data-sortable="true" data-align="right">Tax Rate %</th>
          <th data-field="itcrate" data-sortable="true" data-align="right">ITC Rate %</th>

        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
<!--        <a href="#" class="dropdown-item" ><i class="icon-link" ></i>Ledger Map</a>-->
        <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->


    <!-- Modal Window -->
    <!--<div id="itemgroup_modal_window" class="modal fade" role="dialog" ref="itemgroup_modal_window"  >-->
      <!--<div class="modal-dialog">-->

        <!--&lt;!&ndash; Modal content&ndash;&gt;-->
        <!--<div class="modal-content" style="padding: 0px" >-->

          <!--<ItemGroupForm></ItemGroupForm>-->

        <!--</div>-->
        <!--&lt;!&ndash; End of Modal content&ndash;&gt;-->

      <!--</div>-->
    <!--</div>-->

    <vmodal name="itemgroup_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="750" height="66%" @click="showModal" :draggable="true" >
      <ItemGroupForm v-bind:mygroup="group" v-on:itemgroup_window_closed="closeModal" v-on:itemgroup_saved="loadData"></ItemGroupForm>
    </vmodal>

    <vmodal name="ledger_modal_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="80%" height="92%" @click="showModal" :draggable="true">
      <LedgerMapForm :groupid="groupid" :groupname="groupname" v-on:closeWindow="closeLedgerMapWindow" ></LedgerMapForm>
    </vmodal>
    <!-- End of Modal Window -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

  </div>
  </FocusTrap>
</template>

<script>
// import ItemGroupForm from '@/components/v1/itemgroup/ItemGroupForm.vue'
// import LedgerMapForm from '@/components/v1/itemgroup/GroupLedgerMapForm.vue'
import ItemGroupForm from './ItemGroupForm.vue'
import LedgerMapForm from './GroupLedgerMapForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'ItemGroupView',
  components: {
    ItemGroupForm,
    LedgerMapForm,
    FabButton,
    moment
  },
  data () {
    return {
      readonly: false,
      group: JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}'),
      mytable: {},
      units: [],
      groupid:Number,
      groupname:"",
    }
  },
  created () {

  },
  mounted () {
    const self = this;
    this.$data.mytable = $('#mytable');
    this.$data.mytable.bootstrapTable();

    $('[data-toggle="popover"]').popover();
    $('#mytable').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {

        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === "Refresh") {
          self.loadData();
        }else if($(e.target).text() === 'Modify'){
          self.modifyDocument(id);
        }else if($(e.target).text() === 'Ledger Map'){

          self.showLedgerMapWindow(id, $(row.children('*')[3]).text());
          //ledger_modal_window
        }else if($(e.target).text() == 'Remove'){
          self.removeDocument(id);
        }

      }
    });

    $('.select-search').select2();

    $('#txtsearch').keyup(function () {
      var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
      $('#mytable>tbody>tr').show().filter(function () {
        var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
        return !~text.indexOf(val);
      }).hide();
    });


    $('#itemgroup_modal_window').on('shown.bs.modal', function () {
      $('#txtname').trigger('focus');
    });

    $('#itemgroup_modal_window').on('hide.bs.modal', function () {
      self.clear();
    });

    $('[data-action=remove]').click(function (e) {
      $('#itemgroup_modal_window').modal('hide');
    });

    self.loadData();

  },
  methods: {
    closeModal() {
      this.$modal.hide('itemgroup_modal_window');
    },
    beforeOpen(){
      console.log('beforeOpen');
    },
    beforeClose(){
      this.loadData();
    },
    showModal () {
      this.group= JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":1,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}');
      this.$modal.show('itemgroup_modal_window');
    },
    showLedgerMapWindow(id, groupname){
      this.$data.groupid = id;
      this.$data.groupname = groupname;

      this.$modal.show('ledger_modal_window');
    },
    closeLedgerMapWindow(id){
      this.$data.groupname = "";
      this.$data.groupid = "";
      this.$modal.hide('ledger_modal_window');
    },
    clear(){
      this.group= JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":1,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}');
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      self.group= JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":1,"unit_id":1,"subunit_id":1,"conv_fact":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0},"subunit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/itemgroup/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          self.$data.group = resp.data;
          if(self.$data.group.subunit == null){
            self.$data.group.subunit = self.$data.group.unit;
            self.$data.group.conv_fact = 1;
          }
          self.$modal.show('itemgroup_modal_window');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
      });
    },
    removeDocument (id) {
      const self = this;

      self.$data.group.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.group)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete tms!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/itemgroup`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('itemgroup_modal_window')
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            );
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });

    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      this.$modal.hide('itemgroup_modal_window');

      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/itemgroups/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });

    }
  }
}
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }

  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
