<template>
  <div  id="ledgermapcard" class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">{{groupname}} - Ledger Mapping</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase B2B:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p301" autofocus >
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Sale B2B Local:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2"  v-if="mapping" v-model="mapping.s404" >
              <option value="1" selected>-</option>
              <option v-for="ledger in saleledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase B2C:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2"  v-if="mapping"  v-model="mapping.p302">
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Sale B2C Local:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping"  v-model="mapping.s405" >
              <option value="1" selected>-</option>
              <option v-for="ledger in saleledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase Import:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p305">
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Sale Export:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2"  v-if="mapping" v-model="mapping.s406">
              <option value="1" selected>-</option>
              <option v-for="ledger in saleledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase Composition:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p303" >
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Sale B2B (Other State):</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping"  v-model="mapping.s407" >
              <option value="1" selected>-</option>
              <option v-for="ledger in saleledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase Exempted:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p304" >
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Sale B2C (Other State):</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping"  v-model="mapping.s408" >
              <option value="1" selected>-</option>
              <option v-for="ledger in saleledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase Non GST:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p306" >
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>


          <label class="col-md-2 col-form-label font-weight-semibold">S-B2C (Local Expempted):</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping"  v-model="mapping.s409" >
              <option value="1" selected>-</option>
              <option v-for="ledger in saleledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>


        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase In Eligible:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p307">
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase With RCM:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p308">
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>

          <!--Purchase Others-->
          <label class="col-md-2 col-form-label font-weight-semibold">Purchase With Others:</label>
          <div class="col-md-4">
            <select  class="form-control form-control-select2" v-if="mapping" v-model="mapping.p300">
              <option value="1" selected>-</option>
              <option v-for="ledger in purchledgers" v-bind:value="ledger.code">
                {{ ledger.name }}
              </option>
            </select>
          </div>
          <!--/Purchase Others-->

        </div>


      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600" @click="saveDocument" >Save<i class="icon-database-add ml-2"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'GroupLedgerMapForm',
    props:{
      groupid:{
        type:String,
        default: ()=> ""
      },
      groupname:{
        type:String,
        default: ()=> ""
      },
    },
    data(){
      return {
        code : Number,
        purchledgers:[],
        saleledgers:[],
        mapping: JSON.parse('{"id":0,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"","status":"Active","digits":0}}')
      }
    },
    beforeMount(){
      this.loadMapping(this.groupid);
    },
    created(){
      this.$data.mapping = JSON.parse('{"id":"","p301":1,"p302":1,"p303":1,"p304":1,"p305":1,"p306":1,"p307":1,"p308":1,"p300":1,"s404":1,"s405":1,"s406":1,"s407":1,"s408":1,"s409":1}')

    },
    mounted(){
      this.loadLedgers();
    },
    methods:{
      closeWindow(){
        this.$emit('closeWindow');
      },
      blockCard(){
        $('#ledgermapcard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
      },
      loadMapping(id){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.blockCard();

        this.$data.mapping = JSON.parse('{"id":"","p301":1,"p302":1,"p303":1,"p304":1,"p305":1,"p306":1,"p307":1,"p308":1,"p300":1,"s404":1,"s405":1,"s406":1,"s407":1,"s408":1,"s409":1}');

        fetch(`${process.env.VUE_APP_ROOT_API}fms/itemgroup/ldgrmap/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
              self.$data.mapping = resp.data;
          } else {
            // swal ( { title: "Oh noes!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $('#ledgermapcard').unblock();
        });

      },
      loadLedgers(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.blockCard();
        self.$data.purchledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/accounts/ledger/groups/7,25,27,29/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(_.isArray(resp.data)){
              self.$data.purchledgers  = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });

        self.$data.saleledgers = [];
        fetch(`${process.env.VUE_APP_ROOT_API}api/accounts/ledger/groups/7,24/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            if(_.isArray(resp.data)){
              self.$data.saleledgers  = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        }).finally(function () {
          $('#ledgermapcard').unblock();
        });
      },
      saveDocument(){
        let self = this;

        // this.$data.mapping.id = this.$props.groupid;
        this.$data.mapping.code = parseInt(this.$props.groupcode);
        self.$data.mapping.p301 = parseInt(self.$data.mapping.p301);
        self.$data.mapping.p302 = parseInt(self.$data.mapping.p302);
        self.$data.mapping.p303 = parseInt(self.$data.mapping.p303);
        self.$data.mapping.p304 = parseInt(self.$data.mapping.p304);
        self.$data.mapping.p305 = parseInt(self.$data.mapping.p305);
        self.$data.mapping.p306 = parseInt(self.$data.mapping.p306);
        self.$data.mapping.p307 = parseInt(self.$data.mapping.p307);
        self.$data.mapping.p308 = parseInt(self.$data.mapping.p308);
        self.$data.mapping.p300 = parseInt(self.$data.mapping.p300);

        self.$data.mapping.s404 = parseInt(self.$data.mapping.s404);
        self.$data.mapping.s405 = parseInt(self.$data.mapping.s405);
        self.$data.mapping.s406 = parseInt(self.$data.mapping.s406);
        self.$data.mapping.s407 = parseInt(self.$data.mapping.s407);
        self.$data.mapping.s408 = parseInt(self.$data.mapping.s408);
        self.$data.mapping.s409 = parseInt(self.$data.mapping.s409);

        try{

          const requestOptions = {
            method:  'PUT',
            mode:'cors',
            headers: userService.authHeader(),
            body: JSON.stringify(this.$data.mapping)
          };


          fetch(`${process.env.VUE_APP_ROOT_API}api/itemgroup/ldgrmap`,requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok){
              swal({title: 'Great', type: 'success', text: resp.msg });
              self.closeWindow();
            }else{
              swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
            }
          }).catch(function (err) {
            swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
          });

        }catch (e) {

        }finally {

        }

      }
    }
  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a!important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
